import * as React from "react";
import Layout from "../components/Layout";
import Pricing from "../components/Pricing";
import Numbers from "../components/Numbers";
import CTA from "../components/CTA";
import "./price.sass";

const PricePage = () => {
  return (
    <Layout>
      <Pricing></Pricing>
      <Numbers></Numbers>
      <CTA></CTA>
    </Layout>
  );
};

export default PricePage;
