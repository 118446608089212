import * as React from "react";
import "./Pricing.sass";
import MiniPerk from "./MiniPerk";
import { useState } from "react";
import { Link } from "gatsby";
import support from "../images/icons/support.png";
import features from "../images/icons/features.png";
import blog from "../images/icons/blog.png";

const Pricing = () => {
  const [monthly, setMonthly] = useState(true);
  return (
    <section id="pricing">
      <h2>Un abonnement adapté à vos ambitions</h2>
      <p>
        Weely offre un abonnement simple, adapté aux besoins de votre entreprise
      </p>
      <div id="switcher">
        <p className={monthly ? "active" : ""}>
          <b>Facture mensuelle</b>
        </p>
        <div
          id="switch-out"
          onClick={() => {
            setMonthly(!monthly);
          }}
          onKeyDown={() => {
            setMonthly(!monthly);
          }}
          role="button"
          tabIndex={0}
        >
          <div className={monthly ? "active" : ""} id="switch-in"></div>
        </div>
        <p className={!monthly ? "active" : ""}>
          <b>Facture annuelle</b>
        </p>
      </div>
      <div id="table">
        <div className="price-column">
          <h2 className="price">
            <span id="basic">CHF {monthly ? "39" : "30"}</span> / mois
          </h2>
          <div className="card-title">
            <h2>Basic</h2>
            <p>Idéal pour démarrer et tester Weely.</p>
          </div>
          <ul>
            <li>Pack de démarrage</li>
            <li>Accès au tableau de bord</li>
            <li>Analytics basiques</li>
            <li>Actions réseaux sociaux</li>
          </ul>
          <Link to="/contact" className="button secondary">
            S'abonner
          </Link>
        </div>
        <div className="price-column">
          <h2 className="price">
            <span id="premium">CHF {monthly ? "69" : "55"} </span>/ mois
          </h2>
          <div className="card-title">
            <h2>Premium</h2>
            <p>
              Parfait pour une entreprise qui souhaite développer son image de
              marque.
            </p>
          </div>
          <ul>
            <li>Pack de démarrage</li>
            <li>Accès au tableau de bord</li>
            <li>Analytics basiques</li>
            <li>Actions réseaux sociaux</li>
            <li>Analytics premium</li>
            <li>Roue personnalisable</li>
          </ul>
          <Link to="/contact" className="button secondary">
            S'abonner
          </Link>
        </div>
        <div className="price-column">
          <h2 className="price">
            <span id="pro">CHF {monthly ? "109" : "85"} </span>/ mois
          </h2>
          <div className="card-title">
            <h2>Pro</h2>
            <p>Optimal pour construire une stratégie marketing complète</p>
          </div>
          <ul>
            <li>Pack de démarrage</li>
            <li>Accès au tableau de bord</li>
            <li>Analytics basiques</li>
            <li>Actions réseaux sociaux</li>
            <li>Analytics premium</li>
            <li>Roue personnalisable</li>
            <li>Accès "Golden Wheel"</li>
            <li>Deuxième roue</li>
            <li>Actions personnalisables</li>
            <li>API & Intégration E-commerce</li>
          </ul>
          <Link to="/contact" className="button secondary">
            S'abonner
          </Link>
        </div>
      </div>
      <div id="separator">
        <div className="spacer"></div>
        <h3>Vous bénéficiez également de</h3>
        <div className="spacer"></div>
      </div>
      <div id="added-perks">
        <MiniPerk
          title="Support client 7/7 en ligne"
          text="Toute notre équipe est mobilisée pour répondre à vos questions et vous offrir une expérience à la hauteur de vos attentes."
          image={support}
        ></MiniPerk>
        <MiniPerk
          title="Nouvelles fonctionnalités"
          text="En fonction de votre abonnement, vous bénéficierez du travail permanent de nos équipes en charge du développement de nouvelles fonctionnalités."
          image={features}
        ></MiniPerk>
        <MiniPerk
          title="La Communauté Weely"
          text="Nous partageons régulièrement des témoignages et « Success stories » de nos partenaires."
          image={blog}
        ></MiniPerk>
      </div>
    </section>
  );
};

export default Pricing;
